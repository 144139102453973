import axios from "axios";

export async function postCSVFile(
  origin: string,
  formData: FormData,
  datePicker?: string
): Promise<any> {
  const baseUrl = process.env.VUE_APP_BACKEND_BASE_URL;
  let url = `${baseUrl}/database/import?origin=${origin}`;
  if (datePicker) {
    url = url + "&since=" + datePicker;
  }
  const response = await axios.post(url, formData);
  return response.data;
}
