<template>
  <svg
    x="0px"
    y="0px"
    viewBox="0 0 100 100"
    style="enable-background: new 0 0 100 100"
    xml:space="preserve"
  >
    <g transform="translate(0,-952.36218)">
      <path
        fill="currentColor"
        class="st0"
        d="M44.5,958.4c-1,0-1.9,0.7-2,1.7l-1,7.4c-3.7,0.9-7.1,2.3-10.3,4.3l-5.9-4.6c-0.8-0.6-1.9-0.5-2.6,0.2l-3.9,3.9
		l-3.9,3.9c-0.7,0.7-0.8,1.8-0.2,2.6l4.6,5.9c-1.9,3.1-3.4,6.6-4.3,10.3l-7.4,1c-1,0.1-1.7,1-1.7,2v5.5v5.5c0,1,0.7,1.9,1.7,2l7.4,1
		c0.9,3.7,2.3,7.1,4.3,10.3l-4.6,5.9c-0.6,0.8-0.5,1.9,0.2,2.6l3.9,3.9h0l3.9,3.9c0.7,0.7,1.8,0.8,2.6,0.2l5.9-4.6
		c3.1,1.9,6.6,3.4,10.3,4.3l1,7.4c0.1,1,1,1.7,2,1.7H50h5.5c1,0,1.9-0.7,2-1.7l1-7.4c3.7-0.9,7.1-2.3,10.3-4.3l5.9,4.6
		c0.8,0.6,1.9,0.5,2.6-0.2l3.9-3.9h0h0l3.9-3.9c0.7-0.7,0.8-1.8,0.2-2.6l-4.6-5.9c1.9-3.1,3.4-6.6,4.3-10.3l7.4-1c1-0.1,1.7-1,1.7-2
		v-5.5v-5.5c0-1-0.7-1.9-1.7-2l-7.4-1c-0.9-3.7-2.3-7.1-4.3-10.3l4.6-5.9c0.6-0.8,0.5-1.9-0.2-2.6l-3.9-3.9l-3.9-3.9
		c-0.7-0.7-1.8-0.8-2.6-0.2l-5.9,4.6c-3.1-1.9-6.6-3.4-10.3-4.3l-1-7.4c-0.1-1-1-1.7-2-1.7H50L44.5,958.4L44.5,958.4z M46.3,962.4
		H50h3.7l0.9,7.1c0.1,0.9,0.8,1.5,1.6,1.7c4.2,0.8,8.1,2.5,11.5,4.8c0.7,0.5,1.7,0.4,2.3-0.1l5.6-4.3l2.6,2.6l2.6,2.6l-4.3,5.6
		c-0.5,0.7-0.6,1.6-0.1,2.3c2.3,3.4,3.9,7.3,4.8,11.5c0.2,0.8,0.9,1.5,1.7,1.6l7.1,0.9v3.7v3.7l-7.1,0.9c-0.9,0.1-1.5,0.8-1.7,1.6
		c-0.8,4.2-2.5,8.1-4.8,11.5c-0.5,0.7-0.4,1.7,0.1,2.3l4.3,5.6l-2.6,2.6c0,0,0,0,0,0l-2.6,2.6l-5.6-4.3c-0.7-0.5-1.6-0.6-2.3-0.1
		c-3.4,2.3-7.3,3.9-11.5,4.8c-0.8,0.2-1.5,0.9-1.6,1.7l-0.9,7H50h-3.7l-0.9-7c-0.1-0.9-0.8-1.5-1.6-1.7c-4.2-0.8-8.1-2.5-11.5-4.8
		c-0.7-0.5-1.7-0.4-2.3,0.1l-5.6,4.3l-2.6-2.6c0,0,0,0,0,0l-2.6-2.6l4.3-5.6c0.5-0.7,0.6-1.6,0.1-2.3c-2.3-3.4-3.9-7.3-4.8-11.5
		c-0.2-0.8-0.9-1.5-1.7-1.6l-7-0.9v-3.7v-3.7l7-0.9c0.9-0.1,1.5-0.8,1.7-1.6c0.8-4.2,2.5-8.1,4.8-11.5c0.5-0.7,0.4-1.6-0.1-2.3
		l-4.3-5.6l2.6-2.6l2.6-2.6l5.6,4.3c0.7,0.5,1.6,0.6,2.3,0.1c3.4-2.3,7.3-3.9,11.5-4.8c0.8-0.2,1.5-0.9,1.6-1.7L46.3,962.4
		L46.3,962.4z"
      />
      <path
        fill="currentColor"
        class="st0"
        d="M50,982.4c-11,0-20,9-20,20c0,11,9,20,20,20c11,0,20-9,20-20C70,991.3,61,982.4,50,982.4z M50,986.4
		c8.9,0,16,7.1,16,16c0,8.9-7.1,16-16,16c-8.9,0-16-7.1-16-16C34,993.5,41.1,986.4,50,986.4z"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: "SettingsPic",
};
</script>

<style scoped></style>
