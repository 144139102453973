
import PersonPic from "@/assets/nounproject/PersonPic.vue";
import TeamPic from "@/assets/nounproject/TeamPic.vue";
import LogoutPic from "@/assets/nounproject/LogoutPic.vue";
import ProjectsPic from "@/assets/nounproject/ProjectsPic.vue";
import FaqPic from "@/assets/nounproject/FaqPic.vue";
import SettingsPic from "@/assets/nounproject/SettingsPic.vue";
import { defineComponent } from "vue";
import { useAuthentication } from "@/composites/useAuthentication";
import { useState } from "@/composites/store";
import { BNavItem } from "bootstrap-vue-3";

export default defineComponent({
  name: "BcHeaderMobile",
  components: {
    SettingsPic,
    FaqPic,
    ProjectsPic,
    TeamPic,
    LogoutPic,
    PersonPic,
    BNavItem,
  },
  data: function () {
    return {
      showMenu: false,
    };
  },
  methods: {
    display_menu() {
      this.showMenu = !this.showMenu;
      let body = document.getElementsByTagName("body")[0];
      if (!body.classList.contains("display_menu")) {
        body.classList.add("display_menu");
      } else {
        body.classList.remove("display_menu");
      }
    },
  },
  directives: {
    "b-nav-item": BNavItem,
  },
  setup() {
    const { me } = useState();

    const { isLoading, isAuthenticated, logout } = useAuthentication();
    return {
      me,
      isLoading,
      isAuthenticated,
      logout,
    };
  },
});
