import axios from "axios";

import { useAuthentication } from "@/composites/useAuthentication";

import { getChargeOverview } from "@/api/endpoints/getChargeOverview";
import { getChartData } from "@/api/endpoints/getChartData";
import { getEmployeeChargeabilities } from "@/api/endpoints/getEmployeeChargeabilities";
import { getLastConfirmationTime } from "@/api/endpoints/getLastConfirmationTime";
import { getDatabaseStatus } from "@/api/endpoints/getDatabaseStatus";
import { getMyUser } from "@/api/endpoints/getMyUser";
import { getChargeableProjects } from "@/api/endpoints/getChargeableProjects";
import { getNonChargeableProjects } from "@/api/endpoints/getNonChargeableProjects";

import { postDatabaseUpdate } from "@/api/endpoints/postDatabaseUpdate";
import { postCSVFile } from "@/api/endpoints/postCSVFile";
import { postConfirmWorkedTime } from "@/api/endpoints/postConfirmWorkedTime";

export const API = {
  init,
  close,
  getMyUser,
  getChargeOverview,
  getChartData,
  getEmployeeChargeabilities,
  getLastConfirmationTime,
  getDatabaseStatus,
  getChargeableProjects,
  getNonChargeableProjects,
  postDatabaseUpdate,
  postCSVFile: postCSVFile,
  postConfirmWorkedTime,
  //register new handlers under this object
};

function init(): number {
  const { getAccessTokenSilently } = useAuthentication();
  return axios.interceptors.request.use(async (requestConfig) => {
    const token = await getAccessTokenSilently();
    requestConfig.headers = {
      ...requestConfig.headers,
      Authorization: `Bearer ${token}`,
    };
    return requestConfig;
  });
}

function close(interceptorId: number) {
  if (interceptorId != null) {
    axios.interceptors.request.eject(interceptorId);
  }
}
