
import PersonPic from "@/assets/nounproject/PersonPic.vue";
import TeamPic from "@/assets/nounproject/TeamPic.vue";
import LogoutPic from "@/assets/nounproject/LogoutPic.vue";
import ProjectsPic from "@/assets/nounproject/ProjectsPic.vue";
import FaqPic from "@/assets/nounproject/FaqPic.vue";
import SettingsPic from "@/assets/nounproject/SettingsPic.vue";
import {
  BNav,
  BNavItem,
  BAlert,
  BContainer,
  BRow,
  BCol,
} from "bootstrap-vue-3";
import { logError } from "@/utils/logger";
import { defineComponent } from "vue";
import { useState } from "@/composites/store";
import { User } from "@/models/User";
import { useRoute } from "vue-router";
import { useAuthentication } from "@/composites/useAuthentication";
import { API } from "@/api/api";

export default defineComponent({
  components: {
    SettingsPic,
    ProjectsPic,
    FaqPic,
    TeamPic,
    LogoutPic,
    PersonPic,
    BNav,
    BNavItem,
    BAlert,
    BContainer,
    BRow,
    BCol,
  },
  setup() {
    const { setMe, me } = useState();
    const route = useRoute();
    const { logout } = useAuthentication();
    return {
      setMe,
      me,
      route,
      logout: logout,
      backendError: false,
      start: route.query.start ? route.query.start : "",
      end: route.query.end ? route.query.end : "",
    };
  },
  created() {
    this.fetchMe();
  },
  methods: {
    async fetchMe() {
      this.backendError = false;
      API.getMyUser()
        .then((response) => {
          this.setMe(new User(response));
        })
        .catch((error) => {
          this.backendError = true;
          logError(error);
        });
    },
  },
  directives: {
    "b-nav": BNav,
    "b-row": BRow,
    "b-nav-item": BNavItem,
    "b-col": BCol,
    "b-alert": BAlert,
    "b-container": BContainer,
  },
});
