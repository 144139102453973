<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
    <path
      class="a"
      fill="currentColor"
      d="M50,21.37a11,11,0,0,1,11.1,11c-.4,6.3-5.1,10.9-11.1,11a11,11,0,1,1,0-22ZM40.5,44a15.09,15.09,0,0,0,9.5,3.4A15.37,15.37,0,0,0,59.6,44c5.6,2.7,9.7,7.7,9.7,15.7v7.6c-13,8.8-25.3,8.9-38.5-.1v-7.5c0-8,4-13.1,9.7-15.7Zm25.8-.4A19.67,19.67,0,0,0,62.5,41a14.89,14.89,0,0,0,2.7-8.6,15.22,15.22,0,0,0-15.2-15h0A15,15,0,0,0,37.6,41a18.92,18.92,0,0,0-3.8,2.7,36.59,36.59,0,0,0-2.7,2.9,21.21,21.21,0,0,0-4.3,13.1v5.5c0,1.24.2,4.1.2,4.1h0c19.2,15.4,37.8,7.6,46,0,0,0,.2-3.18.2-4.1v-5.5a20.61,20.61,0,0,0-4.3-13.1S67.05,44.35,66.3,43.57Z"
    />
  </svg>
</template>

<script>
export default {
  name: "PersonPic",
};
</script>

<style scoped></style>
