
import LoginPage from "@/components/LoginPage.vue";
import BcHeader from "@/components/BcHeader.vue";
import MainNav from "@/components/MainNav.vue";

import { defineComponent, onMounted, onUnmounted } from "vue";
import BcHeaderMobile from "@/components/BcHeaderMobile.vue";
import { useAuthentication } from "@/composites/useAuthentication";
import { useState } from "@/composites/store";
import { API } from "@/api/api";

export default defineComponent({
  name: "App",
  components: {
    BcHeader,
    BcHeaderMobile,
    MainNav,
    LoginPage,
  },
  setup: function () {
    const { isLoading, isAuthenticated, getAccessTokenSilently } =
      useAuthentication();
    const { setMe, me } = useState();

    let interceptorId: number | null = null;
    onMounted(() => {
      interceptorId = API.init();
    });
    onUnmounted(() => {
      API.close(interceptorId);
    });

    return {
      me,
      setMe,
      isLoading,
      isAuthenticated,
      getAccessTokenSilently,
    };
  },
});
