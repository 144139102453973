import { createApp } from "vue";
import router from "./router";
import "./assets/main.scss";
import { Calendar, DatePicker } from "v-calendar";
import App from "@/App.vue";
import "@fontsource/source-sans-pro";

import { createAuth0 } from "@auth0/auth0-vue";
import "@fontsource/source-sans-pro";
import VueApexCharts from "vue3-apexcharts";
import Donut from "vue-css-donut-chart";
import "vue-css-donut-chart/dist/vcdonut.css";

const app = createApp(App);

app
  .use(Donut)
  .use(router)
  .use(
    createAuth0({
      domain: process.env.VUE_APP_AUTH0_DOMAIN,
      clientId: process.env.VUE_APP_AUTH0_CLIENTID,
      authorizationParams: {
        audience: process.env.VUE_APP_AUTH0_AUDIENCE,
        redirect_uri: window.location.origin,
      },
      cacheLocation: "localstorage",
    })
  )
  .use(VueApexCharts)
  .component("calendar", Calendar)
  .component("date-picker", DatePicker)
  .mount("#app");
