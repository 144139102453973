import { useAuth0 } from "@auth0/auth0-vue";

export function useAuthentication() {
  const {
    isAuthenticated,
    isLoading,
    getAccessTokenSilently,
    logout,
    loginWithRedirect,
  } = useAuth0();
  return {
    isLoading,
    isAuthenticated,
    logout,
    login: loginWithRedirect,
    getAccessTokenSilently,
  };
}
