export class User {
  id: number | null;
  firstName: string;
  lastName: string;
  targetTime: number | null;
  employeeNumber: string;
  confirmationTimestamp: number | null;
  scope: string;

  constructor({
    id = null,
    firstName = "",
    lastName = "",
    targetTime = null,
    employeeNumber = "",
    confirmationTimestamp = null,
    scope = "",
  } = {}) {
    this.id = id;
    this.firstName = firstName;
    this.lastName = lastName;
    this.targetTime = targetTime;
    this.employeeNumber = employeeNumber;
    this.confirmationTimestamp = confirmationTimestamp;
    this.scope = scope;
  }

  get isValid(): boolean {
    return !!this.id;
  }
}
