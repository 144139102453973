import { computed, ComputedRef, reactive } from "vue";
import { User } from "@/models/User";

interface StateInterface {
  me: User;
  setMe: (newMe: User) => void;
  setLastConfirmationTime: (newTime: any) => void;
  daysSinceLastConfirmation: ComputedRef<number>;
}

const me = reactive(new User());

const daysSinceLastConfirmation = computed(() => {
  const { confirmationTimestamp } = state.me;
  if (confirmationTimestamp === undefined) {
    return null;
  }
  return Math.floor(
    Math.abs(Date.now() - new Date(confirmationTimestamp).getTime()) /
      (1000 * 60 * 60 * 24)
  );
});

const setMe = (newMe) => {
  Object.assign(state.me, newMe);
};

const setLastConfirmationTime = (newTime) => {
  Object.assign(
    state.me,
    new User({
      ...state.me,
      confirmationTimestamp: newTime,
    })
  );
};

const state: StateInterface = {
  me,
  setMe,
  setLastConfirmationTime,
  daysSinceLastConfirmation,
};

export function useState() {
  return state;
}
