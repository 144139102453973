<template>
  <header class="bg-coseegrey pt-4 pb-4">
    <b-container>
      <b-row>
        <b-col>
          <h2 class="text-white text-start">BUSINESS COCKPIT</h2>
          <div
            class="profile-banner overflow-hidden vertical-center"
            v-if="!isLoading && isAuthenticated"
          >
            <h4
              v-if="me"
              class="text-coseeyellow text-uppercase align-middle mb-0"
            >
              {{ me.firstName }} {{ me.lastName }}
            </h4>
          </div>
        </b-col>
        <b-col class="text-end">
          <router-link to="/">
            <img
              alt="Logo Cosee"
              class="cosee-logo"
              src="../assets/logo_cosee_white.svg"
            />
          </router-link>
        </b-col>
      </b-row>
    </b-container>
  </header>
</template>

<script>
import { useAuthentication } from "@/composites/useAuthentication";
import { useState } from "@/composites/store";
import { defineComponent } from "vue";
import { BContainer, BCol, BRow } from "bootstrap-vue-3";

export default defineComponent({
  name: "BcHeader",
  components: {
    BContainer,
    BCol,
    BRow,
  },
  directives: {
    "b-container": BContainer,
    "b-col": BCol,
    "b-row": BRow,
  },
  setup() {
    const { me } = useState();
    const { isLoading, isAuthenticated } = useAuthentication();
    return {
      me,
      isLoading,
      isAuthenticated,
    };
  },
});
</script>

<style lang="scss" scoped>
.cosee-logo {
  height: 3rem;
}

.profile-banner {
  display: flex;
  align-items: center;
}

.profile-picture {
  border-radius: 50%;
  margin: 0 0.5rem;
  max-height: 3rem;
}
</style>
