<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
    <path
      fill="currentColor"
      class="a"
      d="M16.58,19.9H29.91a3.66,3.66,0,0,1,2.34.85l4.23,3.52h0a.94.94,0,0,0,.58.21H75.21a3.66,3.66,0,0,1,3.66,3.66v8.25a.92.92,0,0,0,.92.91h3.66a3.64,3.64,0,0,1,3.64,3.64,3.23,3.23,0,0,1-.1.82l-7.46,32.1A3.68,3.68,0,0,1,76,76.7H16.58A3.67,3.67,0,0,1,12.91,73V23.56h0a3.66,3.66,0,0,1,3.67-3.66ZM75.21,36.39V29.06h0a.92.92,0,0,0-.92-.92H36.73a3.67,3.67,0,0,1-2.35-.85l-4.22-3.52a.94.94,0,0,0-.58-.21H17.49a.92.92,0,0,0-.91.92V62.69h0a.28.28,0,0,0,.28.27.27.27,0,0,0,.27-.21l5.21-22.61A3.68,3.68,0,0,1,25.9,37.3H74.29a.92.92,0,0,0,.92-.91ZM19.56,73H75.23a.92.92,0,0,0,.9-.71l7-30.23h0a.93.93,0,0,0-.69-1.1l-.21,0h-55a1.83,1.83,0,0,0-1.79,1.42L18.67,71.91h0a.92.92,0,0,0,.68,1.1Z"
    />
  </svg>
</template>

<script>
export default {
  name: "ProjectsPic",
};
</script>

<style scoped></style>
