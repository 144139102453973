import axios from "axios";

export async function getNonChargeableProjects(
  from: string,
  to: string
): Promise<any> {
  const baseUrl = process.env.VUE_APP_BACKEND_BASE_URL;
  const url = `${baseUrl}/projects?startDate=${from}&endDate=${to}&chargeable=false`;
  const response = await axios.get(url);
  return response.data;
}
