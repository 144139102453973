import { createRouter, createWebHistory } from "vue-router";

const EmployeeView = () => import("./views/EmployeeView.vue");
const StaffView = () => import("./views/StaffView.vue");
const FaqView = () => import("./views/FaqView.vue");
const ProjectsView = () => import("./views/ProjectsView.vue");
const UploadView = () => import("./views/UploadView.vue");

export default createRouter({
  history: createWebHistory(process.env.BASE_URL),
  sensitive: false,
  strict: false,
  routes: [
    {
      path: "/",
      name: "employee",
      component: EmployeeView,
    },
    {
      path: "/staff",
      name: "staff",
      component: StaffView,
    },
    {
      path: "/projects",
      name: "projects",
      component: ProjectsView,
    },
    {
      path: "/faq",
      name: "faq",
      component: FaqView,
    },
    {
      path: "/settings",
      name: "settings",
      component: UploadView,
    },
  ],
});
