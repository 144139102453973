import axios from "axios";

export interface Employee {
  confirmationTimestamp: number;
  employeeNumber: string;
  firstName: string;
  id: number;
  lastName: string;
  scope: string;
  targetTime: number;
}

export async function getEmployeeChargeabilities(
  from: string,
  to: string,
  granularity: string
): Promise<any> {
  const baseUrl = process.env.VUE_APP_BACKEND_BASE_URL;
  const url = `${baseUrl}/chargeability/all`;
  const response = await axios.get(url, {
    params: {
      startDate: from,
      endDate: to,
      granularity: granularity,
    },
  });
  return response.data;
}
