<template>
  <svg
    x="0px"
    y="0px"
    viewBox="0 0 100 100"
    style="enable-background: new 0 0 100 100"
    xml:space="preserve"
  >
    <g transform="translate(0,-952.36218)">
      <path
        fill="currentColor"
        class="st0"
        d="M50,972c-8.3,0-15.1,6.7-15.1,15c0,3.2,1,6.1,2.7,8.6c-1.3,0.7-2.6,1.6-3.8,2.7c-0.5-0.3-0.9-0.6-1.4-0.9
		c1.1-1.7,1.7-3.7,1.7-5.8c0-6.2-5.1-11.2-11.3-11.2c-6.2,0-11.3,5-11.3,11.2c0,2.1,0.6,4.1,1.7,5.8c-4.3,2.6-7.4,7.2-7.4,13.5v6.1
		c0,0.9,0.7,1.5,1.3,1.9c7.6,5.3,13.2,6.2,19.9,5c0,0,0,0,0,0c19.2,15.4,37.8,7.6,46,0c6.7,1.2,13.5-0.6,20-5.1
		c1-0.6,1.1-1.1,1.2-2.2v-5.8c0-6.3-3.2-10.9-7.4-13.5c1.1-1.7,1.7-3.7,1.7-5.8c0-6.2-5.1-11.2-11.3-11.2c-6.2,0-11.2,5-11.2,11.2
		c0,2.1,0.6,4.1,1.7,5.8c-0.5,0.3-1,0.6-1.4,0.9c-1.2-1-2.4-1.9-3.8-2.6c1.7-2.4,2.7-5.4,2.7-8.6C65.1,978.7,58.3,972,50,972L50,972
		z M50,976c6.2,0,11.1,4.9,11.1,11c-0.4,6.3-5.1,10.9-11.1,11c-6.1,0-11.1-4.9-11.1-11C38.9,980.9,43.8,976,50,976z M22.8,984.3
		c4,0,7.3,3.2,7.3,7.2c0,4-3.2,7.2-7.3,7.2c-4.1-0.2-7.2-2.8-7.3-7.2C15.5,987.4,18.8,984.3,22.8,984.3z M77.2,984.3
		c4,0,7.3,3.2,7.3,7.2c0,4-3.2,7.2-7.3,7.2c-4.2-0.1-7.2-3.1-7.3-7.2C69.9,987.4,73.1,984.3,77.2,984.3z M40.5,998.6
		c2.6,2.1,5.9,3.4,9.5,3.4c3.6,0,7-1.3,9.6-3.4c5.6,2.7,9.7,7.7,9.7,15.7v7.6c-13,8.8-25.3,8.9-38.5-0.1v-7.5
		C30.8,1006.3,34.8,1001.2,40.5,998.6L40.5,998.6z M29.7,1000.3c0.5,0.3,0.9,0.6,1.4,0.9c-2.6,3.4-4.3,7.8-4.3,13.1v5.5
		c-5.6,1.2-11.2-0.1-17-3.9v-5.1c0-5.3,2.6-8.6,6.2-10.4c1.9,1.4,4.3,2.3,6.8,2.3C25.4,1002.6,27.8,1001.8,29.7,1000.3z
		 M70.4,1000.3c1.9,1.4,4.3,2.3,6.8,2.3c2.6,0,4.9-0.9,6.8-2.3c3.6,1.8,6.2,5.1,6.2,10.5v5c-5.8,3.9-11.4,5.1-17,4v-5.5
		c0-5.3-1.6-9.7-4.3-13.1C69.4,1000.9,69.9,1000.6,70.4,1000.3z"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: "TeamPic",
};
</script>

<style scoped></style>
