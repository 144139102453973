<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
    <path
      fill="currentColor"
      class="a"
      d="M86.59,25.83A16.45,16.45,0,0,0,70.14,9.37H29.86A16.45,16.45,0,0,0,13.41,25.83V57.88A16.49,16.49,0,0,0,27,74.09V88a2.94,2.94,0,0,0,1.47,2.45,2.19,2.19,0,0,0,1.23.25,2.5,2.5,0,0,0,1.59-.49l8.23-6a51.72,51.72,0,0,1,30.58-10A16.45,16.45,0,0,0,86.59,57.76Zm-5.4,32.05A11,11,0,0,1,70.26,68.81,57.73,57.73,0,0,0,36.49,79.74l-3.93,2.82v-11a2.73,2.73,0,0,0-2.7-2.7A11,11,0,0,1,18.93,57.88V25.83A11,11,0,0,1,29.86,14.9h40.4A11,11,0,0,1,81.19,25.83Z"
    />
    <path
      fill="currentColor"
      class="a"
      d="M50,26.69c-4.54,0-9,2.95-9,8.6a2.7,2.7,0,0,0,5.4,0c0-2.95,3.19-3.07,3.56-3.07s3.56.12,3.56,3.07v.61a3.1,3.1,0,0,1-1.72,2.82l-2.45,1.35a4,4,0,0,0-2.09,3.57v2.21a2.7,2.7,0,1,0,5.4,0V44.49l1.72-.85A8.55,8.55,0,0,0,59,36v-.73C59,29.64,54.42,26.69,50,26.69Z"
    />
    <path
      fill="currentColor"
      class="a"
      d="M50,51.62c-4.42,0-4.42,6.87,0,6.87S54.42,51.62,50,51.62Z"
    />
  </svg>
</template>

<script>
export default {
  name: "FaqPic",
};
</script>

<style scoped></style>
