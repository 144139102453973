<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
    <path
      fill="currentColor"
      class="a"
      d="M12.28,9.73a3.11,3.11,0,0,0-2.7,3v76a3,3,0,0,0,3,3h38a3,3,0,0,0,3-3v-22a3,3,0,1,0-6,0v19.1h-32v-70h32v19a3,3,0,0,0,6,0V12.73a3,3,0,0,0-3-3H12.28Zm55.9,18.9a3,3,0,0,0-2.6,3.3,2.83,2.83,0,0,0,.9,1.8l14.6,13.8H38.28a3,3,0,0,0,.4,6h42.5l-14.6,13.8a3,3,0,0,0-.4,4.2,3.1,3.1,0,0,0,4.2.4c.1-.1.2-.2.3-.2l20-19a3,3,0,0,0,.1-4.2l-.1-.1-20-19A3.29,3.29,0,0,0,68.18,28.63Z"
    />
  </svg>
</template>

<script>
export default {
  name: "LogoutPic",
};
</script>

<style scoped></style>
