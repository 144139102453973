<template>
  <div>
    <h3>
      <strong>Herzlich Willkommen</strong> auf der Startseite des Business
      Cockpits!
    </h3>
    <p>Bitte logge dich zunächst ein.</p>
    <div>
      <button @click="login()" class="btn btn-coseegreen" type="submit">
        <strong>Login</strong> via GitLab
      </button>
    </div>
  </div>
</template>

<script>
import { useAuthentication } from "@/composites/useAuthentication";
import { defineComponent } from "vue";

export default defineComponent({
  name: "LoginPage",
  setup() {
    const { login } = useAuthentication();
    return {
      login,
    };
  },
});
</script>

<style lang="scss" scoped>
h3 {
  margin-top: 40px;
  margin-bottom: 40px;
}
</style>
